<template>
  <div class="full-page auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

       <!-- Brand logo-->
       <b-link class="brand-logo">
        <b-img width="40" :src="appLogoImage" alt="logo" />
        <h2 class="brand-text text-primary ml-1" style="margin-top: 8px">
          {{ appName }}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Forgot password V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Şifrenizi mi unuttunuz? 🔒
          </b-card-title>
          <b-card-text v-if="verificationSmsSent == false" class="mb-2">
            Telefonunuza şifrenizi yenileyebilmeniz için onay kodu gönderilecek.
          </b-card-text>

          <b-card-text v-else class="mb-2">
            Telefonunuza onay kodu gönderdik. Lütfen son gönderilen onay kodunu
            ve yeni şifrenizi girin.
          </b-card-text>

          <div v-if="verificationSmsSent == false">
            <b-form-group
              class="mt-2"
              label="Telefon Numaranız"
              label-for="register-phone"
            >
              <b-input-group>
                <b-input-group-prepend is-text> (+90) </b-input-group-prepend>
                <b-form-input
                  id="register-phone"
                  v-model="phone"
                  placeholder=""
                  :formatter="phoneFormatter"
                />
              </b-input-group>
            </b-form-group>

            <b-button
              type="submit"
              variant="primary"
              class="mt-3"
              block
              @click="sendVerificationCode()"
            >
              Onay Kodu Gönder
            </b-button>
          </div>

          <div v-else>
            
            <b-form-group label="Onay Kodu" label-for="confirm-code">
              <b-form-input
                id="confirm-code"
                v-model="cofirmCode"
                placeholder="..."
              />
            </b-form-group>

            <b-form-group label-for="register-password" label="Yeni Şifreniz">
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="register-password"
                  v-model="password"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <b-form-group
              label-for="register-password-again"
              label="Yeni Şifreniz Tekrar"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="register-password-again"
                  v-model="passwordConfirm"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <b-button
              type="submit"
              variant="primary"
              class="mt-3"
              block
              @click="resetPassword()"
            >
              Şifre Yenile
            </b-button>
          </div>

          <b-modal
            id="modal-password-updated"
            ref="modal-password-updated"
            hide-footer
            centered
            title="Şifreniz Yenilendi"
          >
            <p class="m-1">
              Şifreniz başarıyla yenilendi. Yeni şifrenizle giriş
              yapabilirsiniz.
            </p>

            <hr style="margin: 20px 10px" />
            <div>
              <b-button
                class="mr-1"
                style="float: right"
                variant="primary"
                @click="goToLogin()"
              >
                Giriş Yap
              </b-button>
            </div>
          </b-modal>

          <p class="text-center mt-2">
            <b-link :to="{ name: 'login' }">
              <feather-icon icon="ChevronLeftIcon" /> Giriş Sayfasına Dön
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BFormGroup,
  BInputGroupAppend,
  BInputGroup,
  BFormInput,
  BButton,
  BInputGroupPrepend,
} from "bootstrap-vue";
import { required, email } from "@validations";
import store from "@/store/index";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeConfig } from "@themeConfig";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    BButton,
    BFormGroup,
    BInputGroupAppend,
    BInputGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    BInputGroupPrepend,
  },
  data() {
    return {
      verificationSmsSent: false,
      phone: "",
      cofirmCode: "",
      password: "",
      passwordConfirm: "",
    };
  },
  mixins: [togglePasswordVisibility],

  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app;
    return {
      appName,
      appLogoImage,
    };
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        return require("@/assets/images/pages/forgot-password-v2-dark.svg");
      }
      return require("@/assets/images/pages/forgot-password-v2.svg");
    },
  },
  methods: {
    showError(errorMessage) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Uyarı!",
          icon: "BellIcon",
          text: errorMessage,
          variant: "danger",
        },
      });
    },

    resetPassword() {
      if (!this.password || !this.passwordConfirm || !this.cofirmCode) {
        this.showError("Lütfen tüm alanları doldurun!");
        return;
      }

      if (this.password.length < 6) {
        this.showError("Şifre minimum 6 karakterden oluşmalıdır!");
        return;
      }

      if (this.password != this.passwordConfirm) {
        this.showError("Şifreler uyuşmuyor!");
        return;
      }

      var payload = {
        phone: this.phone,
        password: this.password,
        verificationCode: this.cofirmCode,
      };
      var self = this;
      store.dispatch("auth/resetPassword", payload).then((response) => {
        if (response) {
          self.$refs["modal-password-updated"].show();
          self.cofirmCode = "";
          self.password = "";
          self.passwordConfirm = "";
        } else {
          self.showError(
            "Şifre yenilenemedi, lütfen telefonunuza gönderilen son onay kodu girdiğinizden emin olun!"
          );
        }
      });
    },

    sendVerificationCode() {
      if (
        this.phone.trim().length != 13 ||
        this.isInt(this.phone.split(" ").join("")) == false ||
        this.phone.startsWith("5") == false
      ) {
        this.showError("Telefon numarası formatı hatalı!");
        return;
      }

      var self = this;
      store.dispatch("auth/isPhoneAvailable", this.phone).then((response) => {
        if (!response) {
          self.showError("Girdiğiniz telefon numarası bulunamadı.");
        } else {
          store
            .dispatch("auth/sendResetPasswordCodeSMS", this.phone)
            .then((response) => {
              if (response) {
                self.verificationSmsSent = true;
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Onay Kodu Gönderildi",
                    icon: "BellIcon",
                    text: "Telefonunuza onay kodu gönderildi.",
                    variant: "success",
                  },
                });
              } else {
                self.showError("Onay kodu gönderilemedi!");
              }
            });
        }
      });
    },

    phoneFormatter: function (newValue) {
      newValue = newValue.trim();
      if (newValue.startsWith("5") == false) {
        newValue = newValue.substring(1, newValue.length);
      }
      var newPhoneValue = "";
      for (var i = 0; i < newValue.length && i < 13; i++) {
        if (
          newPhoneValue.length == 3 ||
          newPhoneValue.length == 7 ||
          newPhoneValue.length == 10
        ) {
          newPhoneValue = newPhoneValue + " ";
        }
        var character = newValue[i];
        if (this.isInt(character)) {
          newPhoneValue = newPhoneValue + character;
        }
      }
      this.phone = newPhoneValue;
      return this.phone;
    },

    isInt(value) {
      return (
        !isNaN(value) &&
        parseInt(Number(value)) == value &&
        !isNaN(parseInt(value, 10)) &&
        !(value + "").includes(".") &&
        !(value + "").includes(",")
      );
    },

    goToLogin() {
      this.$router.push("/giris").catch(() => {});
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
